import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { BaseUrlService } from '../base-url.service';

import { Observable } from 'rxjs';
import type { IOrganizationWithCompetitions } from '@archery-scoring/models/organization.model';

@Injectable({
  providedIn: 'root',
})
export class PublicOrganizationService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);

  getOrganization(organizationUrl: string): Observable<IOrganizationWithCompetitions> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/organizations/url/${organizationUrl}`;
    return this.httpClient.get<IOrganizationWithCompetitions>(url);
  }
}
