import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import type { IOrganizationWithCompetitions, IPublicOrganization } from '@archery-scoring/models/organization.model';
import { pipe, switchMap } from 'rxjs';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { inject, isDevMode, PendingTasks } from '@angular/core';
import { PublicOrganizationService } from '../../services/http/public-organization.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  setError,
  setLoaded,
  setLoading,
  withCallState,
} from '@owain/store-features/features/call-state/call-state.feature';
import { withDevtools } from '@owain/store-features/features/debugging/devtools';
import { immerPatchState } from '@owain/store-features/state/immer.state';

export interface OrganizationState extends IPublicOrganization {}

export const initialState: OrganizationState = {
  organization: null,
};

export const OrganizationStore = signalStore(
  { providedIn: 'root' },
  // withLogger('OrganizationStore'),
  withDevtools('Archery Scoring', 'Organization'),
  withCallState(),
  withState(initialState),
  withMethods(store => {
    const service: PublicOrganizationService = inject(PublicOrganizationService);
    const pendingTasks: PendingTasks = inject(PendingTasks);

    return {
      getOrganizationByUrl: rxMethod<string>(
        pipe(
          switchMap((url: string) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return service.getOrganization(url).pipe(
              tapResponse({
                next: (organization: IOrganizationWithCompetitions): void => {
                  immerPatchState(store, state => {
                    state.organization = organization;
                  });

                  patchState(store, setLoaded());
                },
                error: (err: HttpErrorResponse) => {
                  if (isDevMode()) {
                    console.error('[OrganizationStore]', err);
                  }

                  patchState(store, setError(err.message));
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),
    };
  })
);
